import React, { useImperativeHandle } from "react";
import {
  FDocligneInvoiceInterface,
  InvoiceInterface,
  InvoiceTaskInterface,
} from "../../../interfaces/EdiInterface";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import Typography from "@mui/material/Typography";
import {
  doTypeDtos,
  SAGE_DO_TYPE_ACHAT_CC,
  SAGE_DO_TYPE_ACHAT_DA,
  SAGE_DO_TYPE_ACHAT_PC,
} from "../../../utils/DoceneteteUtils";
import TableCell from "@mui/material/TableCell";
import { useTranslation } from "react-i18next";
import {
  addDisplayInvoiceFDocligne,
  getGlobalNewDoc,
  getNewDoc,
} from "../../../helpers/FDocenteteHelper";
import { priceFormat } from "../../../utils/FormatUtils";
import { getUrlFArticleImage } from "../../../helpers/FileHelper";
import { IMAGE_SIZE_LOGO } from "../../../utils/FileUtils";
import Box from "@mui/material/Box";
import { PRODUCT_PAGE } from "../../../utils/RouteUtils";
import { Link } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import HideImageIcon from "@mui/icons-material/HideImage";
import { Alert, Theme, Tooltip } from "@mui/material";
import SearchComponent from "../SearchComponent";
import CopyClipboardComponent from "../CopyClipboardComponent";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { RootState } from "../../../app/store";
import { SxProps } from "@mui/system";
import { LoadingButton } from "@mui/lab";
import { requestApi } from "../../../helpers/RequestApi";
import { PATCH } from "../../../utils/MethodUtils";
import getErrorApi from "../../../helpers/GetErrorApi";
import { toastr } from "react-redux-toastr";
import { INVOICE_URL } from "../../../utils/UrlsUtils";
import { set } from "../../../app/globalSlice";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

const clone = require("clone");

interface State {
  initInvoice: InvoiceInterface | undefined;
}

interface State2 {
  fDocligne: FDocligneInvoiceInterface;
  doTypeDtos: DoTypeInvoiceInterface[];
  index: number;
  tasks: InvoiceTaskInterface[];
  doPieceDestination: string | null;
}

interface State3 {
  doTypeDtos: DoTypeInvoiceInterface[];
  task: InvoiceTaskInterface;
  doPieceDestination: string | null;
  index: number;
}

interface State4 {
  invoice: InvoiceInterface | undefined;
  doTypeDtos: DoTypeInvoiceInterface[];
  index: number;
}

export interface DoTypeInvoiceInterface {
  doType: number;
  suffix: string | null;
  color: string;
}

const colSpanFDocligne = 5;
const colSpanTask = 7;

const InvoiceTaskComponent = React.memo(
  React.forwardRef(
    ({ task, doTypeDtos, doPieceDestination, index }: State3, ref) => {
      const { t, i18n } = useTranslation();
      const theme = useTheme();
      const getChecked = React.useCallback(() => {
        return !!task.arRef && !task.done;
      }, [task.arRef, task.done]);
      const [checked, setChecked] = React.useState(getChecked());

      const getValue = React.useCallback(() => {
        const r: any = {
          ...task,
          todo: checked,
          doPieceDestination: doPieceDestination,
          doTypeDestination: SAGE_DO_TYPE_ACHAT_CC,
        };
        r.oldId = r.id;
        delete r.id;
        delete r.done;
        return r;
      }, [checked, doPieceDestination, task]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setChecked(getChecked());
      }, [task.arRef, task.done]); // eslint-disable-line react-hooks/exhaustive-deps

      return (
        <TableRow
          sx={{
            ...(index % 2 === 0 && {
              backgroundColor: "rgba(0, 0, 0, 0.05)",
            }),
          }}
        >
          {doTypeDtos.map((doTypeDto) => (
            <React.Fragment key={doTypeDto.doType}>
              <TableCell
                colSpan={colSpanFDocligne}
                sx={{ border: "none", paddingX: 0 }}
              ></TableCell>
              {doTypeDto.doType !== SAGE_DO_TYPE_ACHAT_PC || task.done ? (
                <TableCell
                  colSpan={colSpanTask}
                  sx={{ border: "none", paddingX: 0 }}
                ></TableCell>
              ) : (
                <>
                  {task.arRef ? (
                    <>
                      <TableCell sx={{ border: "none" }}></TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <CopyClipboardComponent
                          text={task.afReffourniss}
                          sx={{ wordBreak: "keep-all" }}
                        />
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {task.quantity}
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <>
                          {priceFormat(
                            Number(task.unitPrice) * 100 * task.quantity,
                            i18n.language,
                            "EUR"
                          )}
                          (unit:{" "}
                          {priceFormat(
                            Number(task.unitPrice) * 100,
                            i18n.language,
                            "EUR"
                          )}
                          )
                        </>
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <Link
                          to={PRODUCT_PAGE + "/" + task.fArticle.arRef}
                          style={{
                            textDecoration: "none",
                            color: "inherit",
                          }}
                        >
                          {task.fArticle.fArticleProp.images ? (
                            <img
                              src={
                                (process.env.REACT_APP_API_URL ?? "") +
                                getUrlFArticleImage(
                                  task.fArticle.arRef,
                                  task.fArticle.fArticleProp.images[0],
                                  IMAGE_SIZE_LOGO
                                )
                              }
                              className={"objectFitCover"}
                              alt={
                                "[" +
                                task.fArticle.arRef +
                                "] " +
                                task.fArticle.arDesign
                              }
                              loading="lazy"
                            />
                          ) : (
                            <HideImageIcon
                              sx={{
                                width: IMAGE_SIZE_LOGO,
                                height: IMAGE_SIZE_LOGO,
                              }}
                            />
                          )}
                        </Link>
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        <CopyClipboardComponent
                          className="RobotoMono"
                          sx={{
                            paddingInline: 1,
                            fontWeight: "bold",
                            wordBreak: "keep-all",
                            textAlign: "center",
                            color: theme.palette.success.main,
                          }}
                          text={task.fArticle.arRef}
                        />
                      </TableCell>
                      <TableCell sx={{ border: "none" }}>
                        {task.fArticle.arDesign}
                      </TableCell>
                    </>
                  ) : (
                    <TableCell colSpan={colSpanTask} sx={{ border: "none" }}>
                      <Typography>{t("sentence.edi.notFound")}</Typography>
                      <pre>{JSON.stringify(task, undefined, 2)}</pre>
                    </TableCell>
                  )}
                </>
              )}
            </React.Fragment>
          ))}
        </TableRow>
      );
    }
  )
);

const InvoiceFDocligneComponent = React.memo(
  React.forwardRef(
    (
      { fDocligne, tasks, doTypeDtos, doPieceDestination, index }: State2,
      ref
    ) => {
      const { t, i18n } = useTranslation();
      const theme = useTheme();
      const fournisseurs = useAppSelector(
        (state: RootState) => state.globalState.fournisseurs
      );
      const getCheckedTasks = React.useCallback(() => {
        const result: number[] = [];
        if (tasks) {
          // @ts-ignore
          for (const [index, task] of tasks.entries()) {
            if (!task.done) {
              result.push(index);
            }
          }
        }
        return result;
      }, [tasks]);
      const [checkedTasks, setCheckedTasks] = React.useState(getCheckedTasks());

      const getShowBorderX = React.useCallback(
        (doType: number) => {
          let prefix =
            doTypeDtos.find((d) => d.doType === doType)?.suffix ?? "";
          prefix = prefix.charAt(0).toUpperCase() + prefix.slice(1);
          return (
            (fDocligne.display[doType] !== "" &&
              (fDocligne.doType === doType ||
                // @ts-ignore
                fDocligne["dlPiece" + prefix] !== "")) ||
            (fDocligne.display["prev" + doType] &&
              fDocligne.display["prev" + doType] ===
                fDocligne.display["next" + doType])
          );
        },
        [doTypeDtos, fDocligne]
      );
      const globalNewDoc = getGlobalNewDoc(fDocligne, doTypeDtos);
      const thisFournisseur = fournisseurs?.find(
        (f) => f.userIdentifier === fDocligne.ctNum
      );

      const getValue = React.useCallback(() => {
        return tasks.map((t, i) => {
          const r: any = {
            ...t,
            todo: checkedTasks.includes(i),
            doPieceDestination: doPieceDestination,
            doTypeDestination: SAGE_DO_TYPE_ACHAT_CC,
          };
          r.oldId = r.id;
          delete r.id;
          delete r.done;
          return r;
        });
      }, [checkedTasks, doPieceDestination, tasks]);

      useImperativeHandle(ref, () => ({
        getValue() {
          return getValue();
        },
      }));

      React.useEffect(() => {
        setCheckedTasks(getCheckedTasks());
      }, [tasks]); // eslint-disable-line react-hooks/exhaustive-deps

      let rowSpan = tasks.length;
      if (rowSpan === 0) {
        rowSpan = 1;
      }
      const totalQuantityTask = tasks
        .map((t) => t.quantity)
        .reduce((partialSum, a) => partialSum + a, 0);
      return (
        <>
          {globalNewDoc && (
            <TableRow
              sx={{
                ...(index % 2 !== 0 && {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                }),
              }}
            >
              {doTypeDtos.map((doTypeDto) => {
                const newDoc = getNewDoc(fDocligne, doTypeDto.doType);
                return (
                  <React.Fragment key={doTypeDto.doType}>
                    <TableCell
                      colSpan={colSpanFDocligne}
                      sx={{
                        border: "none",
                        ...(getShowBorderX(doTypeDto.doType) && {
                          borderLeft: "2px " + doTypeDto.color + " solid",
                          borderRight: "2px " + doTypeDto.color + " solid",
                        }),
                        ...(newDoc && {
                          borderTop: "2px " + doTypeDto.color + " solid",
                          borderBottom: "2px " + doTypeDto.color + " dashed",
                        }),
                      }}
                    >
                      {newDoc && (
                        <>
                          {fDocligne.doType === doTypeDto.doType ? (
                            <>
                              <Typography
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <span
                                  style={{
                                    color: theme.palette.primary.main,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {thisFournisseur?.ctIntitule}{" "}
                                  <CopyClipboardComponent
                                    className="RobotoMono"
                                    component="span"
                                    text={fDocligne.ctNum}
                                    sx={{ wordBreak: "keep-all" }}
                                  />
                                </span>
                                <CopyClipboardComponent
                                  className="RobotoMono"
                                  component="span"
                                  text={fDocligne.fDocentete.doPiece}
                                  sx={{ wordBreak: "keep-all" }}
                                />
                                <span>
                                  {t("word.doStatut.label") + ": "}
                                  <span
                                    style={{
                                      fontWeight: "bold",
                                      color: theme.palette.primary.main,
                                    }}
                                  >
                                    {fDocligne.fDocentete.doStatutString}
                                  </span>
                                </span>
                              </Typography>
                              <Table size="small">
                                <TableHead>
                                  <TableRow>
                                    <TableCell sx={{ paddingY: 0 }}>
                                      {t("word.commentaires")}
                                    </TableCell>
                                    <TableCell sx={{ paddingY: 0 }}>
                                      {t("word.doDatelivr")}
                                    </TableCell>
                                    <TableCell sx={{ paddingY: 0 }}>
                                      {t("word.doRef")}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  <TableRow>
                                    <TableCell sx={{ paddingY: 0 }}>
                                      {fDocligne.fDocentete.commentaires}
                                    </TableCell>
                                    <TableCell sx={{ paddingY: 0 }}>
                                      {new Date(
                                        fDocligne.fDocentete.doDatelivr
                                      ).toLocaleString(i18n.language, {
                                        dateStyle: "long",
                                        timeZone: "America/Cuiaba",
                                      })}
                                    </TableCell>
                                    <TableCell sx={{ paddingY: 0 }}>
                                      {fDocligne.fDocentete.doRef}
                                    </TableCell>
                                  </TableRow>
                                </TableBody>
                              </Table>
                            </>
                          ) : (
                            <>
                              {doTypeDto.suffix && (
                                <Box sx={{ textAlign: "center" }}>
                                  <CopyClipboardComponent
                                    className="RobotoMono"
                                    component="span"
                                    sx={{ wordBreak: "keep-all" }}
                                    text={
                                      // @ts-ignore
                                      fDocligne[
                                        "dlPiece" +
                                          doTypeDto.suffix
                                            .charAt(0)
                                            .toUpperCase() +
                                          doTypeDto.suffix.slice(1)
                                      ] ?? ""
                                    }
                                  />
                                </Box>
                              )}
                            </>
                          )}
                        </>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{ border: "none" }}
                      colSpan={colSpanTask}
                    ></TableCell>
                  </React.Fragment>
                );
              })}
            </TableRow>
          )}
          {Array.from(Array(rowSpan).keys()).map((indexTask) => (
            <TableRow
              key={indexTask}
              sx={{
                ...(index % 2 === 0 && {
                  backgroundColor: "rgba(0, 0, 0, 0.05)",
                }),
              }}
            >
              {doTypeDtos.map((doTypeDto) => {
                const show = fDocligne.doType === doTypeDto.doType;
                let dlQte = fDocligne.dlQte;
                let dlMontantht = "";
                if (fDocligne.doType === doTypeDto.doType) {
                  dlMontantht = fDocligne.dlMontantht;
                } else {
                  if (doTypeDto.doType === SAGE_DO_TYPE_ACHAT_PC) {
                    dlMontantht = fDocligne.dlPuBc;
                  }
                  // @ts-ignore
                  dlQte = fDocligne["dlQte" + doTypeDto.suffix];
                }
                const showBorderX = getShowBorderX(doTypeDto.doType);
                const sx: SxProps<Theme> = {
                  border: "none",
                  ...(showBorderX && {
                    borderBottom: "2px " + doTypeDto.color + " dotted",
                  }),
                  ...(!show && {
                    padding: 0,
                  }),
                };
                let priceLine = 0;
                let priceTask = 0;
                if (tasks.length > 0) {
                  priceLine = Math.round(
                    (Number(dlMontantht) * 100) / Number(dlQte)
                  );
                  priceTask = Math.round(
                    Number(tasks[indexTask].unitPrice) * 100
                  );
                }
                const afReffournisses = [
                  // @ts-ignore
                  ...new Set(tasks.map((task) => task.afReffourniss)),
                ];
                return (
                  <React.Fragment key={doTypeDto.doType}>
                    {indexTask === 0 && (
                      <>
                        <TableCell
                          rowSpan={rowSpan}
                          sx={{
                            ...sx,
                            ...(showBorderX && {
                              borderLeft: "2px " + doTypeDto.color + " solid",
                            }),
                          }}
                        >
                          {show && (
                            <Link
                              to={PRODUCT_PAGE + "/" + fDocligne.fArticle.arRef}
                              style={{
                                textDecoration: "none",
                                color: "inherit",
                              }}
                            >
                              {fDocligne.fArticle.fArticleProp.images ? (
                                <img
                                  src={
                                    (process.env.REACT_APP_API_URL ?? "") +
                                    getUrlFArticleImage(
                                      fDocligne.fArticle.arRef,
                                      fDocligne.fArticle.fArticleProp.images[0],
                                      IMAGE_SIZE_LOGO
                                    )
                                  }
                                  className={"objectFitCover"}
                                  alt={
                                    "[" +
                                    fDocligne.fArticle.arRef +
                                    "] " +
                                    fDocligne.dlDesign
                                  }
                                  loading="lazy"
                                />
                              ) : (
                                <HideImageIcon
                                  sx={{
                                    width: IMAGE_SIZE_LOGO,
                                    height: IMAGE_SIZE_LOGO,
                                  }}
                                />
                              )}
                            </Link>
                          )}
                        </TableCell>
                        <TableCell rowSpan={rowSpan} sx={{ ...sx }}>
                          {showBorderX && fDocligne.fArticle?.arRef && (
                            <CopyClipboardComponent
                              className="RobotoMono"
                              sx={{
                                paddingInline: 1,
                                fontWeight: "bold",
                                textAlign: "center",
                                color: theme.palette.success.main,
                                wordBreak: "keep-all",
                                ...(!show && {
                                  marginX: 2,
                                }),
                              }}
                              text={fDocligne.fArticle.arRef}
                            />
                          )}
                          {show && (
                            <>
                              {afReffournisses.map(
                                (afReffourniss, indexAfReffourniss) => (
                                  <CopyClipboardComponent
                                    key={indexAfReffourniss}
                                    text={afReffourniss}
                                    sx={{
                                      textAlign: "center",
                                      wordBreak: "keep-all",
                                    }}
                                  />
                                )
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell rowSpan={rowSpan} sx={{ ...sx }}>
                          {show && <>{fDocligne.dlDesign}</>}
                        </TableCell>
                        <TableCell rowSpan={rowSpan} sx={{ ...sx }}>
                          {show && <>{Number(dlQte)}</>}
                        </TableCell>
                        <TableCell
                          rowSpan={rowSpan}
                          sx={{
                            ...sx,
                            ...(showBorderX && {
                              borderRight: "2px " + doTypeDto.color + " solid",
                            }),
                          }}
                        >
                          {show && (
                            <>
                              {priceFormat(
                                Number(dlMontantht) * 100,
                                i18n.language,
                                "EUR"
                              )}
                              (unit:{" "}
                              {priceFormat(
                                (Number(dlMontantht) * 100) / Number(dlQte),
                                i18n.language,
                                "EUR"
                              )}
                              )
                            </>
                          )}
                        </TableCell>
                      </>
                    )}
                    {tasks.length === 0 ? (
                      <>
                        {indexTask === 0 && (
                          <TableCell
                            colSpan={colSpanTask}
                            sx={{ border: "none" }}
                          >
                            {show &&
                              doTypeDto.doType === SAGE_DO_TYPE_ACHAT_PC && (
                                <Tooltip title={t("word.reliquat")}>
                                  <CloseIcon color="error" />
                                </Tooltip>
                              )}
                          </TableCell>
                        )}
                      </>
                    ) : (
                      <>
                        {tasks[indexTask].done ? (
                          <TableCell
                            colSpan={colSpanTask}
                            sx={{
                              paddingY: 0,
                              border: "none",
                            }}
                          ></TableCell>
                        ) : (
                          <>
                            <TableCell sx={{ border: "none" }}>
                              <>
                                {doTypeDto.doType === SAGE_DO_TYPE_ACHAT_PC && (
                                  <>
                                    {totalQuantityTask === Number(dlQte) ? (
                                      <DoneIcon color="success" />
                                    ) : (
                                      <Tooltip title={t("word.partial")}>
                                        <CloseIcon color="warning" />
                                      </Tooltip>
                                    )}
                                  </>
                                )}
                              </>
                            </TableCell>
                            <TableCell
                              sx={{
                                paddingY: 0,
                                border: "none",
                              }}
                            >
                              {show && (
                                <>
                                  <CopyClipboardComponent
                                    text={tasks[indexTask].afReffourniss}
                                    sx={{ wordBreak: "keep-all" }}
                                  />
                                </>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                paddingY: 0,
                                border: "none",
                                ...(totalQuantityTask !== Number(dlQte) && {
                                  color: theme.palette.error.main,
                                }),
                              }}
                            >
                              {show && tasks[indexTask].quantity}
                            </TableCell>
                            <TableCell
                              sx={{
                                paddingY: 0,
                                border: "none",
                                ...(priceLine > priceTask && {
                                  color: theme.palette.success.main,
                                }),
                                ...(priceLine < priceTask && {
                                  color: theme.palette.error.main,
                                }),
                              }}
                            >
                              {show && (
                                <>
                                  {priceFormat(
                                    Number(tasks[indexTask].unitPrice) *
                                      100 *
                                      tasks[indexTask].quantity,
                                    i18n.language,
                                    "EUR"
                                  )}
                                  (unit:{" "}
                                  {priceFormat(
                                    Number(tasks[indexTask].unitPrice) * 100,
                                    i18n.language,
                                    "EUR"
                                  )}
                                  )
                                </>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{ border: "none", paddingX: 0 }}
                            ></TableCell>
                            <TableCell
                              sx={{ border: "none", paddingX: 0 }}
                            ></TableCell>
                            <TableCell
                              sx={{ border: "none", paddingX: 0 }}
                            ></TableCell>
                          </>
                        )}
                      </>
                    )}
                  </React.Fragment>
                );
              })}
            </TableRow>
          ))}
        </>
      );
    }
  )
);

const InvoiceFooterComponent = React.memo(
  React.forwardRef(({ invoice, doTypeDtos, index }: State4, ref) => {
    const { t, i18n } = useTranslation();
    const theme = useTheme();

    const getTotal = React.useCallback(
      (doTypeDto: DoTypeInvoiceInterface) => {
        let total = 0;
        if (invoice?.fDoclignes) {
          for (const fDocligne of invoice.fDoclignes) {
            if (fDocligne.doType !== doTypeDto.doType) {
              continue;
            }
            total += Number(fDocligne.dlMontantht);
          }
        }
        return total;
      },
      [invoice]
    );

    const totalTask = (invoice?.invoiceTasks ?? [])
      .filter((invoiceTask: InvoiceTaskInterface) => !invoiceTask.done)
      .map((invoiceTask: InvoiceTaskInterface) => {
        return Number(invoiceTask.unitPrice) * invoiceTask.quantity;
      })
      .reduce((partialSum, a) => partialSum + a, 0);

    return (
      <TableRow
        sx={{
          backgroundColor: theme.palette.primary.main,
        }}
      >
        {doTypeDtos.map((doTypeDto, indexDoTypeDto) => {
          const total = getTotal(doTypeDto);
          return (
            <React.Fragment key={indexDoTypeDto}>
              <TableCell sx={{ border: "none", paddingX: 0 }}></TableCell>
              <TableCell sx={{ border: "none", paddingX: 0 }}></TableCell>
              <TableCell sx={{ border: "none", paddingX: 0 }}></TableCell>
              <TableCell sx={{ border: "none", paddingX: 0 }}></TableCell>
              <TableCell
                sx={{
                  border: "none",
                  paddingX: 0,
                  color: theme.palette.primary.contrastText,
                }}
              >
                {total > 0 &&
                  priceFormat(Number(total) * 100, i18n.language, "EUR")}
              </TableCell>
              {doTypeDto.doType === SAGE_DO_TYPE_ACHAT_PC ? (
                <>
                  <TableCell sx={{ border: "none", paddingX: 0 }}></TableCell>
                  <TableCell sx={{ border: "none", paddingX: 0 }}></TableCell>
                  <TableCell sx={{ border: "none", paddingX: 0 }}></TableCell>
                  <TableCell
                    sx={{
                      border: "none",
                      color: theme.palette.primary.contrastText,
                    }}
                  >
                    {priceFormat(Number(totalTask) * 100, i18n.language, "EUR")}
                  </TableCell>
                  <TableCell sx={{ border: "none", paddingX: 0 }}></TableCell>
                  <TableCell sx={{ border: "none", paddingX: 0 }}></TableCell>
                  <TableCell sx={{ border: "none", paddingX: 0 }}></TableCell>
                </>
              ) : (
                <TableCell
                  colSpan={colSpanTask}
                  sx={{ border: "none", paddingX: 0 }}
                ></TableCell>
              )}
            </React.Fragment>
          );
        })}
      </TableRow>
    );
  })
);

const InvoiceComponent: React.FC<State> = React.memo(({ initInvoice }) => {
  const dispatch = useAppDispatch();

  const formatInvoice = React.useCallback(
    (thisInvoice: InvoiceInterface | undefined) => {
      addDisplayInvoiceFDocligne(
        thisInvoice?.fDoclignes ?? [],
        doTypeDtos.filter((x) =>
          [
            SAGE_DO_TYPE_ACHAT_DA,
            SAGE_DO_TYPE_ACHAT_PC,
            SAGE_DO_TYPE_ACHAT_CC,
          ].includes(x.doType)
        )
      );
      if (thisInvoice?.fDoclignes) {
        for (const fDocligne of thisInvoice.fDoclignes) {
          fDocligne.ref = React.createRef();
        }
      }
      if (thisInvoice?.invoiceTasks) {
        for (const task of thisInvoice.invoiceTasks) {
          task.ref = React.createRef();
        }
      }
      return thisInvoice;
    },
    []
  );
  const [invoice, setInvoice] = React.useState<InvoiceInterface | undefined>(
    formatInvoice(initInvoice)
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const { t, i18n } = useTranslation();
  const token = useAppSelector((state: RootState) => state.globalState.token);

  const applyTaxes = React.useCallback(async () => {
    setLoading(true);
    let tasksToSend: InvoiceTaskInterface[] = [];
    if (invoice?.fDoclignes) {
      for (const fDocligne of invoice?.fDoclignes) {
        tasksToSend = [
          ...tasksToSend,
          ...(fDocligne.ref.current?.getValue() ?? []),
        ];
      }
    }
    if (invoice?.invoiceTasks) {
      for (const task of invoice?.invoiceTasks.filter((t) => t.dlNo === null)) {
        const v = task.ref.current?.getValue();
        if (v) {
          tasksToSend.push(v);
        }
      }
    }
    tasksToSend = clone(tasksToSend);
    for (const taskToSend of tasksToSend) {
      delete taskToSend.ref;
      // @ts-ignore
      delete taskToSend.fArticle;
    }
    const response = await requestApi({
      method: PATCH,
      path: INVOICE_URL + "/" + invoice?.id + "/tasks",
      allowError: true,
      token: token,
      body: {
        invoiceTasks: tasksToSend,
      },
    });
    if (response.statusCode === 200) {
      setInvoice(formatInvoice(response.content));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [
    invoice?.fDoclignes,
    invoice?.invoiceTasks,
    invoice?.id,
    token,
    formatInvoice,
    t,
  ]);

  const refresh = React.useCallback(async () => {
    setLoading(true);
    const response = await requestApi({
      method: PATCH,
      path: INVOICE_URL + "/" + invoice?.id + "/refresh",
      allowError: true,
      token: token,
      body: {},
    });
    if (response.statusCode === 200) {
      setInvoice(formatInvoice(response.content));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [formatInvoice, invoice?.id, t, token]);

  const changeIsNew = React.useCallback(async () => {
    setLoading(true);
    const response = await requestApi({
      method: PATCH,
      path: INVOICE_URL + "/" + invoice?.id,
      allowError: false,
      token: token,
      body: {
        isNew: !invoice?.isNew,
      },
    });
    if (response.statusCode === 200) {
      setInvoice(formatInvoice(response.content));
      dispatch(set({ newEdi: response.content.newEdi }));
    } else if (response.statusCode === 401) {
      toastr.info(t("word.info"), t("error.reconnect"));
    } else {
      for (let message of getErrorApi(response.content)) {
        toastr.error(t("word.error"), t(message));
      }
    }
    setLoading(false);
  }, [dispatch, formatInvoice, invoice?.id, invoice?.isNew, t, token]);

  React.useEffect(() => {
    setInvoice(formatInvoice(initInvoice));
  }, [initInvoice]); // eslint-disable-line react-hooks/exhaustive-deps

  const doPieceDestination =
    invoice?.fDoclignes.find((f) => f.doType === SAGE_DO_TYPE_ACHAT_CC)
      ?.fDocentete.doPiece ?? null;
  const thisDoTypeDtos = doTypeDtos.filter((x) => {
    return [
      SAGE_DO_TYPE_ACHAT_DA,
      SAGE_DO_TYPE_ACHAT_PC,
      SAGE_DO_TYPE_ACHAT_CC,
    ].includes(x.doType);
  });

  let fDocligneIndex = 0;
  return (
    <>
      {!invoice ? (
        <SearchComponent nbColumn={1} nbLines={1} />
      ) : (
        <>
          {invoice?.errors && invoice.errors.length > 0 && (
            <Box sx={{ marginBottom: 2 }}>
              {invoice.errors.map((error, indexError) => (
                <Alert severity="error" key={indexError}>
                  {error}
                </Alert>
              ))}
            </Box>
          )}
          <Accordion>
            <AccordionSummary
              sx={{
                paddingY: 1,
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              <Typography variant="h5">{t("word.edi")}</Typography>
              <CopyClipboardComponent
                text={invoice.rawData}
                icon={<ContentCopyIcon />}
                sx={{ wordBreak: "keep-all" }}
              />
            </AccordionSummary>
            <AccordionDetails>
              <pre>{invoice.rawData}</pre>
            </AccordionDetails>
          </Accordion>
          <ul>
            <li>{t("word.id") + ": " + invoice.id}</li>
            <li>{t("word.supplier") + ": " + invoice.userIdentifier}</li>
            <li>{t("word.invoice") + ": " + invoice.invoiceId}</li>
            <li>
              {t("word.received") +
                ": " +
                new Date(invoice.created).toLocaleString(i18n.language, {
                  dateStyle: "long",
                  timeStyle: "medium",
                  timeZone: "America/Cuiaba",
                })}
            </li>
            <li>
              {t("word.date") +
                ": " +
                new Date(invoice.invoiceDate).toLocaleString(i18n.language, {
                  dateStyle: "long",
                  timeZone: "Etc/UTC",
                })}
            </li>
            <li>
              {t("column.isNew") + ": "}
              <LoadingButton
                variant="text"
                color="inherit"
                sx={{
                  borderRadius: "50%",
                  minWidth: "auto",
                  padding: "12px",
                }}
                loading={loading}
                onClick={changeIsNew}
              >
                {invoice.isNew ? (
                  <DoneIcon color="success" />
                ) : (
                  <CloseIcon color="error" />
                )}
              </LoadingButton>
            </li>
            {invoice.similarDoPieces && invoice.similarDoPieces.length > 0 && (
              <li>
                {t("word.similarDoPieces") + ": " + invoice.similarDoPieces}
              </li>
            )}
          </ul>
          <Table size="small" sx={{ width: "auto", marginY: 1 }}>
            <TableHead>
              <TableRow>
                {thisDoTypeDtos.map((doTypeDto) => (
                  <React.Fragment key={doTypeDto.doType}>
                    <TableCell colSpan={colSpanFDocligne}>
                      <Typography sx={{ textAlign: "center" }}>
                        {t("word.fdocentete.doType." + doTypeDto.doType)}
                      </Typography>
                    </TableCell>
                    <TableCell colSpan={colSpanTask}>
                      {doTypeDto.doType === SAGE_DO_TYPE_ACHAT_PC &&
                        invoice.invoiceTasks.length > 0 && (
                          <Typography sx={{ textAlign: "center" }}>
                            {t("word.edi")}
                          </Typography>
                        )}
                    </TableCell>
                  </React.Fragment>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {invoice?.fDoclignes.map((fDocligne) => {
                if (getGlobalNewDoc(fDocligne, thisDoTypeDtos)) {
                  fDocligneIndex++;
                }
                fDocligneIndex++;
                return (
                  <InvoiceFDocligneComponent
                    key={fDocligne.dlNo}
                    index={fDocligneIndex}
                    fDocligne={fDocligne}
                    ref={fDocligne.ref}
                    doTypeDtos={thisDoTypeDtos}
                    doPieceDestination={doPieceDestination}
                    tasks={invoice.invoiceTasks.filter(
                      (t) => t.dlNo === fDocligne.dlNo
                    )}
                  />
                );
              })}
              {invoice?.invoiceTasks
                .filter((t) => t.dlNo === null)
                .map((task, indexTask) => {
                  fDocligneIndex++;
                  return (
                    <InvoiceTaskComponent
                      doTypeDtos={thisDoTypeDtos}
                      task={task}
                      ref={task.ref}
                      index={fDocligneIndex}
                      key={indexTask}
                      doPieceDestination={doPieceDestination}
                    />
                  );
                })}
              <InvoiceFooterComponent
                invoice={invoice}
                doTypeDtos={thisDoTypeDtos}
                index={fDocligneIndex}
              />
            </TableBody>
          </Table>
          {invoice?.invoiceTasks &&
            invoice.invoiceTasks.filter((i) => i.done).length === 0 && (
              <>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box>
                    {(invoice.dlNoTransformed === null ||
                      invoice.dlNoTransformed.length === 0) && (
                      <LoadingButton
                        variant="contained"
                        onClick={refresh}
                        loading={loading}
                      >
                        {t("word.refresh")}
                      </LoadingButton>
                    )}
                  </Box>
                  <Box>
                    {invoice.invoiceTasks.filter((i) => !i.done).length > 0 && (
                      <LoadingButton
                        variant="contained"
                        onClick={applyTaxes}
                        disabled={!invoice.isNew}
                        loading={loading}
                      >
                        {t("word.apply")}
                      </LoadingButton>
                    )}
                  </Box>
                </Box>
              </>
            )}
        </>
      )}
    </>
  );
});

export default InvoiceComponent;

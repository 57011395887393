export const PREFIX_URL = "/api";
export const LOGIN_ME_URL = PREFIX_URL + "/users/me";
export const FCOMPTET_URL = PREFIX_URL + "/f_comptets";
export const AUTHENTICATION_TOKEN_URL = "/authentication-token";
export const FARTICLE_URL = PREFIX_URL + "/f_articles";
export const FARTICLE_SELLING_URL = FARTICLE_URL + "/{identifier}/selling";
export const SIRET_URL = PREFIX_URL + "/siret";
export const REVIEW_URL = PREFIX_URL + "/reviews";
export const CONTENT_URL = PREFIX_URL + "/contents";
export const FILE_CONTENT = PREFIX_URL + "/files";
export const CATEGORY_URL = PREFIX_URL + "/categories";
export const DRIVER_URL = PREFIX_URL + "/drivers";
export const CART_FARTICLE_URL = PREFIX_URL + "/cart_f_articles";
export const CART_URL = PREFIX_URL + "/carts";
export const FARTFOURNISSES_URL = PREFIX_URL + "/f_artfournisses";
export const CONFIGURATION_F_ARTICLE_OPTIONS_URL =
  PREFIX_URL + "/configuration_f_article_options";
export const FDOCENTETES_URL = PREFIX_URL + "/f_docentetes";
export const EXPEDITION_URL = PREFIX_URL + "/expeditions";
export const ORDER_URL = PREFIX_URL + "/orders";
export const FDOCENTETE_FORM_URL = FDOCENTETES_URL + "/{doPiece}/payment-form";
export const POSTORDERS_URL = PREFIX_URL + "/post_orders";
export const GET_FAMILY_URL = PREFIX_URL + "/univers/{id}/families";
export const GET_BRAND_URL = PREFIX_URL + "/families/{id}/brands";
export const GET_MODEL_URL = PREFIX_URL + "/brands/{id}/models";
export const UPDATE_FFAMILLE_MAPPINGS_URL =
  PREFIX_URL + "/f_famille_mappings/all";
export const NOTIFICATION_URL = PREFIX_URL + "/notifications";
export const EDIT_USER_URL = PREFIX_URL + "/edit_users";
export const MAIL_CONFIRMATION_URL = PREFIX_URL + "/mail_confirmations";
export const FILTER_IMAGE_URL = PREFIX_URL + "/filter_images";
export const SAV_URL = PREFIX_URL + "/savs";
export const MANUAL_CRON_URL = PREFIX_URL + "/manual_crons";
export const SAV_COMMENTS_URL = SAV_URL + "/{id}/sav_comments";
export const SAV_COMMENT_URL = PREFIX_URL + "/sav_comments";
export const FECRITUREC_URL = PREFIX_URL + "/f_ecriturecs";
export const ACCOUNTING_SITUATION_URL = PREFIX_URL + "/accounting_situations";
export const USER_URL = PREFIX_URL + "/users";
export const FILTER_URL = PREFIX_URL + "/filters";
export const FILTER_VALUE_URL = PREFIX_URL + "/filter_values";
export const CATEGORY_CHILDREN_URL = CATEGORY_URL + "/{id}/childrens";
export const CATEGORY_IMAGE_URL = CATEGORY_URL + "/image";
export const FILTER_FILTER_ARTICLE_FARTICLE_URL =
  PREFIX_URL + "/filter_article/f_articles";
export const POST_PUT_FILTER_VALUE_ARTICLE_URL =
  PREFIX_URL + "/multiple/filter_value_articles";
export const LOST_PASSWORD_URL = PREFIX_URL + "/lost_passwords";
export const F_COMPTET_USER_URL = PREFIX_URL + "/f_comptet_users";
export const USER_TRANSFORM_URL = USER_URL + "/transform";
export const USER_FORM_URL = USER_URL + "/{userIdentifier}/form";
export const FLIVRAISON_URL = PREFIX_URL + "/f_livraisons";
export const CONFIGURATION_GROUP_URL = PREFIX_URL + "/configuration_groups";
export const CONFIGURATION_URL = PREFIX_URL + "/configurations";
export const FARTICLE_SELLING_ORDER_URL = PREFIX_URL + "/f_articles/order";
export const CONTACT_URL = PREFIX_URL + "/contacts";
export const INVOICE_URL = PREFIX_URL + "/invoices";
export const CONTACT_MESSAGE_URL = PREFIX_URL + "/contact_messages";
export const CONTACT_MESSAGES_URL = CONTACT_URL + "/{id}/contact_messages";
export const QWANT_IMAGE = PREFIX_URL + "/q-want/images";
export const QWANT_FILE = PREFIX_URL + "/q-want/file";
export const INVENTORY_FARTICLE = PREFIX_URL + "/inventory_f_articles";
export const FILTER_VALUE_ASSO_URL = PREFIX_URL + "/filter_value_assos";
export const FDEPOTS_URL = PREFIX_URL + "/f_depots";
export const LOGS_URL = PREFIX_URL + "/logs";
export const LOG_URL = PREFIX_URL + "/log";
export const FARTICLE_SELLING_FARTICLE_URL =
  PREFIX_URL + "/f_articles/selling/{type}";
export const UPDATE_ENTITY_CONTENT_URL = PREFIX_URL + "/update/contents";
export const TAG_URL = PREFIX_URL + "/tags";
export const F_LOTSERIE_URL = PREFIX_URL + "/f_lotseries";
export const SAV_USER_FDOCENTETE_URL =
  PREFIX_URL + "/savs/f_docentetes/{doPiece}";
export const MAIL_URL = PREFIX_URL + "/mails";
export const MAIL_PREVIEW_URL = PREFIX_URL + "/mails/{id}/preview";
export const MAIL_TEMPLATE_URL = PREFIX_URL + "/mail_templates";
export const PAYMENT_HISTORY_URL = PREFIX_URL + "/payment_histories";
export const COMMANDE_CONFIRMATION_BANQUE_URL = "/commande_confirmation_banque";
export const TEST_SEND_MAIL_URL = PREFIX_URL + "/mail-send-test";
export const CREDIT_PAYMENT_URL = PREFIX_URL + "/credit_payments";
export const CREDIT_FDOCENTETES_URL = PREFIX_URL + "/credit_f_docentetes";
export const FISCAL_CODES_URL = PREFIX_URL + "/fiscal_codes";
export const FISCAL_CODES_MULTIPLE_URL = FISCAL_CODES_URL + "/multiple";
export const NEWS_URL = PREFIX_URL + "/news";
export const USER_PROP_URL = PREFIX_URL + "/user_props";
export const FARTICLE_ICECAT_URL = PREFIX_URL + "/f_article_props/{id}/icecat";
export const GET_ICECAT_URL = PREFIX_URL + "/icecat";
export const BON_DE_COMMANDE_URL =
  PREFIX_URL + "/f_docentetes/{id}/bonCommande";
export const ICECAT_PRODUCT_LIST_URL = PREFIX_URL + "/icecat/product-list";
export const ICECAT_IMAGE_URL = PREFIX_URL + "/icecat/image/{id}";
export const ICECAT_LOGIN_COOKIE_URL = PREFIX_URL + "/icecat/login-cookie";
export const ICECAT_ASK_PRODUCT_URL = PREFIX_URL + "/icecat/ask-product";
export const FDOCENTETES_ELASTIC_SEARCH_URL =
  PREFIX_URL + "/f_docentetes/{doPiece}/elastic-search";
export const FAVORIS_URL = PREFIX_URL + "/favoriss";
export const MAIL_SEND_HISTORY_URL = PREFIX_URL + "/mail_send_histories";
export const SAV_SERVICE_TODO_URL = PREFIX_URL + "/sav_service_todos";
export const FDOCENTETES_NBDAYTRANSFORMPLINDE_URL =
  FDOCENTETES_URL + "/{doPiece}/nbDayTransformPlInDe";
export const MULTIPLE_MAIL_URL = PREFIX_URL + "/multiple_mails";
export const PDF_UPLOADED_URL = PREFIX_URL + "/pdf_uploadeds";
export const ARRIVAGE_URL = PREFIX_URL + "/arrivages";
export const FDOCLIGNE_URL = PREFIX_URL + "/f_doclignes";
export const FARTSTOCK_URL = PREFIX_URL + "/f_artstocks";
export const FOURNISSEUR_PROP_URL = PREFIX_URL + "/fournisseur_props";
export const FOURNISSEUR_ARTICLE_URL = PREFIX_URL + "/articless";
export const CART_PRODUCTS_URL = PREFIX_URL + "/carts/products";

import { DoTypeInvoiceInterface } from "../components/common/edi/InvoiceComponent";
import theme from "../app/Theme";

export const SAGE_DO_TYPE_VENTE_DE = 0; // Devis
export const SAGE_DO_TYPE_VENTE_BC = 1; // Bon de commande
export const SAGE_DO_TYPE_VENTE_PL = 2; // Préparation de livraison
export const SAGE_DO_TYPE_VENTE_BL = 3; // Bon de livraison
export const SAGE_DO_TYPE_VENTE_BR = 4; // Bon de retour
export const SAGE_DO_TYPE_VENTE_BF = 5; // Bon d'avoir financier
export const SAGE_DO_TYPE_VENTE_FA = 6; // Facture
export const SAGE_DO_TYPE_VENTE_FC = 7; // Facture comptabilisée
export const SAGE_DO_TYPE_VENTE_AR = 8; // Archive
export const SAGE_DO_TYPE_ACHAT_DA = 10; // Demande d'achat
export const SAGE_DO_TYPE_ACHAT_PC = 11; // Préparation commande
export const SAGE_DO_TYPE_ACHAT_CC = 12; // Commande confirmée
export const SAGE_DO_TYPE_ACHAT_LI = 13; // Livraison
export const SAGE_DO_TYPE_ACHAT_RE = 14; // Reprise
export const SAGE_DO_TYPE_ACHAT_AV = 15; // Avoir
export const SAGE_DO_TYPE_ACHAT_FA = 16; // Facture
export const SAGE_DO_TYPE_ACHAT_FC = 17; // Facture comptabilisée
export const SAGE_DO_TYPE_ACHAT_AR = 18; // Archive
export const SAGE_DO_TYPE_STOCK_ME = 20; // Mouvements d'entrée
export const SAGE_DO_TYPE_STOCK_MS = 21; // Mouvements de sortie
export const SAGE_DO_TYPE_STOCK_DS = 22; // Dépréciation du stock
export const SAGE_DO_TYPE_STOCK_VDD = 23; // Virement dépôt à dépôt
export const SAGE_DO_TYPE_STOCK_PDF = 24; // Préparation de fabrication
export const SAGE_DO_TYPE_STOCK_OF = 25; // Ordre de fabrication
export const SAGE_DO_TYPE_STOCK_BDF = 26; // Bon de fabrication
export const SAGE_DO_TYPE_STOCK_ARC = 27; // Archive
export const SAGE_DO_TYPE_STOCK_TI = 30; // Ticket
export const SAGE_DO_TYPE_STOCK_DI1 = 40; // Document interne 1
export const SAGE_DO_TYPE_STOCK_DI2 = 41; // Document interne 2
export const SAGE_DO_TYPE_STOCK_DI3 = 42; // Document interne 3
export const SAGE_DO_TYPE_STOCK_DI4 = 43; // Document interne 4
export const SAGE_DO_TYPE_STOCK_DI5 = 44; // Document interne 5
export const SAGE_DO_TYPE_STOCK_DI6 = 45; // Document interne 6
export const SAGE_DO_TYPE_STOCK_SDR = 46; // Saisie du réalisé
export const SAGE_DO_TYPE_STOCK_DA = 47; // Document archive

export const CAN_SEARCH_SAGE_DO_TYPE = [
  SAGE_DO_TYPE_VENTE_DE, // Devis
  SAGE_DO_TYPE_VENTE_BC, // Bon de commande
  SAGE_DO_TYPE_VENTE_PL, // Préparation de livraison
  SAGE_DO_TYPE_VENTE_FA, // Facture
  SAGE_DO_TYPE_VENTE_FC, // Facture comptabilisée
];

export const doTypeDtos: DoTypeInvoiceInterface[] = [
  {
    doType: SAGE_DO_TYPE_ACHAT_DA,
    suffix: "de",
    color: theme.palette.warning.main,
  },
  {
    doType: SAGE_DO_TYPE_ACHAT_PC,
    suffix: null,
    color: theme.palette.primary.main,
  },
  {
    doType: SAGE_DO_TYPE_ACHAT_CC,
    suffix: "bc",
    color: theme.palette.info.main,
  },
  {
    doType: SAGE_DO_TYPE_ACHAT_LI,
    suffix: "bl",
    color: theme.palette.error.main,
  },
  {
    doType: SAGE_DO_TYPE_ACHAT_FA,
    suffix: null,
    color: theme.palette.secondary.main,
  },
];
